import { useRef, useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { logger } from "../../../utils/logger";

export function useAudioMonitor(
  audioSource: HTMLAudioElement | MediaStream | undefined,
  isRecording: boolean
) {
  let interval = useRef<NodeJS.Timeout>();
  const [audioPulse, setAudioPulse] = useState<number>(0);
  const [averageVolume, setAverageVolume] = useState<number>(0); // State to store average volume
  const [hasPulse, setHasPulse, hasPulseRef] = useStateRef(false);

  useEffect(() => {
    const threshold = averageVolume > 1 ? averageVolume / 2 : 15;

    if (isRecording && audioPulse > threshold) {
      setHasPulse(true);
    }

    if (!isRecording) {
      setHasPulse(false);
    }
  }, [isRecording, audioPulse, averageVolume, setHasPulse]);

  useEffect(() => {
    if (!audioSource) {
      return; // Exit early if there's no audio source
    }

    const audioCtx = new AudioContext();
    let sourceNode: MediaElementAudioSourceNode | MediaStreamAudioSourceNode;

    if (audioSource instanceof HTMLAudioElement) {
      sourceNode = audioCtx.createMediaElementSource(audioSource);
    } else if (audioSource instanceof MediaStream) {
      sourceNode = audioCtx.createMediaStreamSource(audioSource);
    } else {
      logger.error("Invalid audio source type");
      return;
    }

    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Connect nodes: source -> analyser
    sourceNode.connect(analyser);

    let totalVolume = 0; // To keep a running total of volume
    let count = 0; // To keep track of the number of samples
    const volumeThreshold = 1; // Define a threshold to filter out low sound levels
    let monitorActive = true; // Variable to handle cleanup

    // Function to monitor audio levels and calculate average volume
    const monitorLevels = () => {
      if (!monitorActive) return; // Exit if monitor is deactivated

      analyser.getByteFrequencyData(dataArray);
      const avgVolume =
        dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;

      // Only include samples above the threshold
      if (avgVolume > volumeThreshold) {
        totalVolume += avgVolume;
        count += 1;

        // Update the average volume periodically
        if (count % 10 === 0) {
          // Adjust frequency of updates as needed
          setAverageVolume(totalVolume / count);
        }
      }

      requestAnimationFrame(monitorLevels); // Continue monitoring
    };

    monitorLevels(); // Start monitoring

    interval.current = setInterval(() => {
      analyser.getByteTimeDomainData(dataArray);

      let minVal = 255;
      let maxVal = 0;
      for (let i = 0; i < bufferLength; i++) {
        minVal = Math.min(minVal, dataArray[i]);
        maxVal = Math.max(maxVal, dataArray[i]);
      }

      const percentage = ((maxVal - minVal) / 128) * 100;
      setAudioPulse(percentage);
    }, 100);

    // Cleanup function
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }

      // Stop the monitoring function
      monitorActive = false;

      // Disconnect the audio nodes
      sourceNode.disconnect(analyser);
      analyser.disconnect();

      // Close the AudioContext
      audioCtx.close();
    };
  }, [audioSource]);

  return { audioPulse, averageVolume, hasPulse, hasPulseRef };
}
