import {
  Image,
  Box,
  Button,
  Center,
  Flex,
  Text,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import {
  TiAnchorOutline,
  TiChartBar,
  TiCogOutline,
  TiMessages,
} from "react-icons/ti";
import { AiFillAudio } from "react-icons/ai";
import { useRouter } from "next/router";
import { FiBook } from "react-icons/fi";
import { useSettings } from "../hooks/use-settings";
import { useEffect, useRef, useState } from "react";
import Analytics from "./analytics";
import { useUser } from "../hooks/use-user";
import BetaPassword from "./beta-password";
import { detectIOS } from "../utils/detect-ios";
import { useConversionTracker } from "../hooks/use-conversion-tracker";
import AppProvider from "../pages/app-provider";
import { isChina } from "../utils/is-china";
import useWindowHeight from "../hooks/use-window-height";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { FaGraduationCap, FaRegStar } from "react-icons/fa";
import { useUserGroups } from "../hooks/use-user-groups";
import { useMixpanel } from "../hooks/use-mixpanel";
import { siteConfig } from "../configuration/config";
import { useLocalization } from "../hooks/use-localization";
import { hotjar } from "react-hotjar";
import { logger } from "../utils/logger";
import { useLanguageLock } from "../hooks/use-language-lock";
import { useVoiceSelection } from "../hooks/use-voice-selection";
import { addBreadcrumb, captureException } from "@sentry/nextjs";
import ErrorBoundary from "./error-boundary";
import { logout } from "../utils/logout";
import { interpolateString } from "../utils/interpolate-string";
import { MdLogout } from "react-icons/md";
import SidebarButton from "./sidebar-button";

interface LayoutApp {
  children: React.ReactNode;
}

export default function LayoutApp({ children }: LayoutApp) {
  const localization = useLocalization();
  const l = localization.l;
  const [didLoad, setDidLoad] = useState(false);
  const innerHeight = useWindowHeight();
  const { user, userIsLoading, systemUser, subscriptionIsActive } = useUser();
  const { hasGroups } = useUserGroups();
  const {
    settings,
    settingsAreLoading,
    settingsError,
    userNotLoggedIn,
    settingsAreValidating,
    saveInitialSettings,
  } = useSettings();
  const router = useRouter();
  const initialSettingSaved = useRef(false);

  useConversionTracker();
  useMixpanel(true);
  useLanguageLock();
  useVoiceSelection();

  useEffect(() => {
    hotjar.initialize(3461987, 1);
  }, []);

  useEffect(() => {
    if (!userIsLoading && user && process.env.NEXT_PUBLIC_PARTNER_DOMAIN) {
      const message = `Checking user provider. Env partner: ${process.env.NEXT_PUBLIC_PARTNER_DOMAIN}, User provider: ${user.provider}.`;
      logger.info(message);

      addBreadcrumb({
        category: "log",
        message,
        level: "info",
      });

      // Special cases for punkify and clubedealemao
      if (
        user.provider === "punkify.com" &&
        process.env.NEXT_PUBLIC_PARTNER_DOMAIN === "clubedealemao.com"
      ) {
        addBreadcrumb({
          category: "log",
          message: "Allow punkify user on clubedealemao",
          level: "info",
        });

        return;
      } else if (
        user.provider === "clubedealemao.com" &&
        process.env.NEXT_PUBLIC_PARTNER_DOMAIN === "punkify.com"
      ) {
        addBreadcrumb({
          category: "log",
          message: "Allow clubedealemao user on punkify",
          level: "info",
        });
        return;
      }

      if (user?.provider !== process.env.NEXT_PUBLIC_PARTNER_DOMAIN) {
        const message = `User provider [${user?.provider}] does not match partner domain [${process.env.NEXT_PUBLIC_PARTNER_DOMAIN}]. Signing out.`;

        logger.error(message);

        captureException(message, {
          extra: {
            origin: "layout-app.tsx",
          },
        });

        logout({ callbackUrl: "/authentication/partner/error" });
      }
    }
  }, [user, userIsLoading, router]);

  useEffect(() => {
    if (
      initialSettingSaved.current ||
      settingsAreValidating ||
      settingsAreLoading ||
      !systemUser
    ) {
      return;
    }

    if (!settings.id) {
      initialSettingSaved.current = true;
      saveInitialSettings();
    }
  }, [
    settingsAreValidating,
    settingsAreLoading,
    systemUser,
    settings,
    saveInitialSettings,
  ]);

  useEffect(() => {
    if (!didLoad && user && settings?.id) {
      setDidLoad(true);
    }
  }, [user, settings, didLoad]);

  useEffect(() => {
    if (router.pathname.includes("/app/chat") && detectIOS()) {
      document.body.classList.add("chat-layout-ios");
    }
  }, [router.pathname]);

  if (userNotLoggedIn) {
    window.location.href = "/authentication/signin";
    return null;
  }

  if (userIsLoading || settingsAreLoading) {
    return (
      <Flex
        w={"100%"}
        h={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
        bg="brand.gray.900"
      >
        <Spinner size="xl" color={"brand.light.main"} />
      </Flex>
    );
  }

  if (!didLoad) {
    if (!user && typeof window !== "undefined") {
      window.location.href = "/authentication/signin";
      return <div />;
    }

    if (settingsError) {
      return (
        <Flex
          w={"100%"}
          h={"100vh"}
          alignItems={"center"}
          justifyContent={"center"}
          bg="brand.gray.900"
          color="white"
        >
          <Text>{l["layout.unableToConnect"]}</Text>
        </Flex>
      );
    }

    if (!subscriptionIsActive && typeof window !== "undefined") {
      window.location.href = "/billing";
      return <div />;
    }

    if (
      (settings.firstVisit || Object.keys(settings).length === 0) &&
      router.pathname !== "/app/intro"
    ) {
      router.push("/app/intro");
      return <div />;
    }
  }

  if (
    user?.paymentFailed &&
    router.pathname !== "/app/account" &&
    typeof window !== "undefined"
  ) {
    window.location.href = "/app/account";
    return <div />;
  }

  return (
    <>
      <AppProvider localization={localization}>
        {/* <AdjustHeight /> */}
        <BetaPassword />
        {!isChina() && <Analytics gKey={"G-38FBRZ326Q"} />}

        <Show below="md">
          <Flex
            flexDir={"column"}
            id="mobile-wrapper"
            data-page={router.pathname.substring(1).replace(/\//g, "-")}
            height={innerHeight}
          >
            <Box id="mobile-header-spacer" h="50px"></Box>
            <Flex
              borderBottom="1px solid"
              borderColor="special.mobileNavBorderColor"
              h={"50px"}
              backgroundColor={"special.mobileNavBackgroundColor"}
              justifyContent={"space-between"}
              alignItems="center"
              position="fixed"
              w="full"
              zIndex={20}
              id="mobile-header"
              top="0"
            >
              <Box flex={1}>
                <Menu>
                  <MenuButton
                    colorScheme="brand.secondary"
                    bg="transparent"
                    color="special.mobileNavBackgroundContrastColor"
                    border="1px solid"
                    borderColor="special.mobileNavBackgroundContrastColor"
                    mx={5}
                    as={IconButton}
                    size={"md"}
                    icon={<HiOutlineMenuAlt2 fontSize="25px" />}
                  ></MenuButton>
                  <MenuList zIndex={20}>
                    <MenuItem as="a" href="/app/">
                      {l["layout.home"]}
                    </MenuItem>
                    <MenuItem as="a" href="/app/chat?free=true">
                      {l["layout.chat"]}
                    </MenuItem>
                    <MenuItem as="a" href="/app/pronunciation">
                      {l["layout.pronunciation"]}
                    </MenuItem>
                    <MenuItem as="a" href="/app/wordbook">
                      {l["layout.wordbook"]}
                    </MenuItem>
                    {!siteConfig.hideProgressMenuLink && (
                      <MenuItem as="a" href="/app/progress">
                        {l["layout.progress"]}
                      </MenuItem>
                    )}
                    {hasGroups && (
                      <MenuItem as="a" href="/app/programs">
                        {l["layout.programs"]}
                      </MenuItem>
                    )}
                    <MenuItem as="a" href="/app/settings">
                      {l["layout.settings"]}
                    </MenuItem>
                    {!siteConfig.hideManageAccountLink && (
                      <MenuItem as="a" href="/app/account">
                        {l["layout.account"]}
                      </MenuItem>
                    )}

                    {siteConfig.parentService && (
                      <MenuItem as="a" href={siteConfig.parentService.link}>
                        {interpolateString(l["layout.parentServiceLink"], {
                          name: siteConfig.parentService.name,
                        })}
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Box>
              <Center>
                {siteConfig.mobileLogoPath && (
                  <Image
                    h={"26px"}
                    my={"12px"}
                    src={siteConfig.mobileLogoPath}
                    alt={siteConfig.serviceName}
                  />
                )}
              </Center>
              <Box flex={1}></Box>
            </Flex>

            <Box h={innerHeight - 50} bg="brand.light.main">
              <ErrorBoundary>{children}</ErrorBoundary>
            </Box>
          </Flex>
        </Show>
        <Show above="md">
          <Flex>
            <Flex
              id="desktop-sidebar"
              flex="1"
              h="100vh"
              bg="white"
              maxW="200px"
              backgroundColor={"special.desktopSidebar"}
              color={"white"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              borderRightWidth={"1px"}
              borderRightColor="special.desktopSidebarBorderColor"
            >
              <div>
                <Center mt={"desktopSidebarLogoTopOffset"} mb={12}>
                  <Image
                    maxH={{ base: "100%" }}
                    h={siteConfig.appLogoHeight}
                    src={siteConfig.appLogoPath}
                    alt={siteConfig.serviceName}
                    opacity={siteConfig.isDefault ? 0.5 : 1}
                  />
                </Center>

                <Box px="15px" justifyContent={"flex-start"}>
                  <SidebarButton
                    icon={<TiAnchorOutline />}
                    href="/app"
                    label={l["layout.home"]}
                  />

                  <SidebarButton
                    icon={<TiMessages />}
                    href="/app/chat?free=true"
                    label={l["layout.chat"]}
                  />

                  <SidebarButton
                    icon={<AiFillAudio />}
                    href="/app/pronunciation"
                    label={l["layout.pronunciation"]}
                  />

                  <SidebarButton
                    icon={<FiBook />}
                    href="/app/wordbook"
                    label={l["layout.wordbook"]}
                  />

                  {siteConfig.hideProgressMenuLink ? null : (
                    <Button
                      leftIcon={<TiChartBar />}
                      color="special.desktopSidebarText"
                      _hover={{
                        backgroundColor: "special.desktopSidebarTextHover",
                      }}
                      variant="ghost"
                      size="md"
                      mb={2}
                      as="a"
                      href="/app/progress"
                    >
                      <Text w={"110px"} textAlign="left">
                        {l["layout.progress"]}
                      </Text>
                    </Button>
                  )}

                  {hasGroups && (
                    <SidebarButton
                      icon={<FaGraduationCap />}
                      href="/app/programs"
                      label={l["layout.programs"]}
                    />
                  )}

                  <SidebarButton
                    icon={<TiCogOutline />}
                    href="/app/settings"
                    label={l["layout.settings"]}
                  />

                  {siteConfig.parentService && (
                    <SidebarButton
                      icon={<MdLogout />}
                      href={siteConfig.parentService.link}
                      label={interpolateString(l["layout.parentServiceLink"], {
                        name: siteConfig.parentService.name,
                      })}
                    />
                  )}
                </Box>
              </div>

              {!siteConfig.hideManageAccountLink || siteConfig.reviewLink ? (
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  py={3}
                  borderTop={"1px solid #eee"}
                >
                  {!siteConfig.hideManageAccountLink && (
                    <Link
                      color={"gray.400"}
                      display={"flex"}
                      fontWeight={"bold"}
                      fontSize={"small"}
                      as="a"
                      href="/app/account"
                    >
                      {l["layout.manageAccount"]}
                    </Link>
                  )}

                  {siteConfig.reviewLink && (
                    <Flex
                      as="a"
                      href={siteConfig.reviewLink}
                      target="_blank"
                      rel="nofollow noreferrer"
                      pt={2}
                      fontSize={"2xs"}
                      color={"gray.500"}
                      _hover={{ textDecor: "underline" }}
                    >
                      <Box pt={"3px"}>
                        <FaRegStar />
                      </Box>
                      <Text ml={1}>{l["layout.leaveReview"]}</Text>
                    </Flex>
                  )}
                </Flex>
              ) : null}
            </Flex>

            <Box flex="2" h="100vh" bg="brand.light.alt">
              <ErrorBoundary>{children}</ErrorBoundary>
            </Box>
          </Flex>
        </Show>
      </AppProvider>
    </>
  );
}
