import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import Analytics from "./analytics";
import { Section } from "./section";
import { ProductHuntBadge } from "../components/product-hunt-badge";
import { AiFillStar } from "react-icons/ai";
import { useUser } from "../hooks/use-user";
import { signIn, signOut } from "next-auth/react";
import { NewsletterSignup } from "./newsletter-signup";
import BetaPassword from "./beta-password";
import { useConversionTracker } from "../hooks/use-conversion-tracker";
import { isChina } from "../utils/is-china";
import { localeFromLangCode } from "../utils/locale-from-header";
import FacebookPixel from "./facebook-pixel";
import TiktokPixel from "./tiktok-pixel";
import { useMixpanel } from "../hooks/use-mixpanel";
import Script from "next/script";
import { siteConfig } from "../configuration/config";
import { RiArrowDownSLine } from "react-icons/ri";
import { authTarget } from "../types/constants";
import LanguageSelectorLandingDynamic from "./language-selector-landing-dynamic";
import { LandingLocale } from "../localization/landing";

interface LayoutLanding {
  children: React.ReactNode;
  l: LandingLocale;
  baseLangCode: string;
}

export default function LayoutLanding({
  children,
  l: defaultL,
  baseLangCode,
}: LayoutLanding) {
  const l = defaultL || localeFromLangCode("en-US").l;
  const user = useUser(false);
  const router = useRouter();

  const isDefaultEnglish = !baseLangCode || baseLangCode === "en";
  const isFrontpageOrBilling =
    router.pathname === "/" ||
    router.pathname.startsWith("/billing") ||
    router.pathname.startsWith("/authentication") ||
    router.pathname.startsWith("/campaign") ||
    router.pathname.startsWith("/invitation") ||
    router.pathname.startsWith("/labs");

  const isQuiz = router.pathname.startsWith("/quiz");
  const isPartners = router.pathname.startsWith("/partners");
  const isCampaign = router.pathname.startsWith("/campaign");
  const isBilling = router.pathname === "/billing";

  const isPartnerAuth =
    router.pathname.startsWith("/authentication/partner") ||
    router.pathname.startsWith("/authentication/iframe");

  useConversionTracker();
  useMixpanel(true);

  const onLogin = () => {
    user.sessionUser
      ? (window.location.href = "/app")
      : signIn(authTarget, { callbackUrl: "/app" });
  };

  return (
    <Box className={siteConfig.overrideLanding ? "partner-landing" : ""}>
      <BetaPassword />

      {!isChina() && (
        <>
          <Analytics gKey={"G-LG4J2SKNZW"} />

          <link
            rel="preconnect"
            href="https://dev.visualwebsiteoptimizer.com"
          />
          <Script id="vwoCode" strategy="beforeInteractive">
            {`
            window._vwo_code || (function() { /* VWO script code */ })();
            `}
          </Script>

          {router.pathname !== "/" && (
            <>
              <FacebookPixel />
              <TiktokPixel />
            </>
          )}
        </>
      )}
      {!isCampaign && (
        <Box
          w={"100%"}
          maxW="1100px"
          margin="0 auto"
          display={{ base: "none", md: "block" }}
          id="service-bar"
        >
          <Flex py={1} px={3}>
            <Text fontSize="xs">{l["layout.serviceBar.text"]}</Text>

            <Flex ml={10}>
              <Flex>
                {[0, 1, 2, 3, 4].map((k) => (
                  <AiFillStar
                    key={k}
                    color="var(--chakra-colors-brand-green-500)"
                    fontSize="16px"
                    style={{ marginTop: "1px" }}
                  />
                ))}
                <Text fontSize="xs" ml={1}>
                  {l["layout.serviceBar.community"]}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
      <Box
        backgroundImage="url('/pattern2.png')"
        backgroundPosition="center"
        backgroundRepeat="repeat"
      >
        <Flex
          justifyContent={"space-between"}
          p={3}
          w={"100%"}
          maxW="1100px"
          margin="0 auto"
          id="top-bar"
        >
          <Flex flex="1" display={"flex"}>
            <Link href="/" _hover={{ textDecoration: "none" }} mr={4}>
              <Image
                maxH={{ base: "100%" }}
                h={{
                  base: `calc(${siteConfig.landingLogoHeight} - 5px)`,
                  sm: siteConfig.landingLogoHeight,
                }}
                my={{ base: "8px", sm: "10px" }}
                src={siteConfig.landingLogoPath}
                alt="Talkio AI"
              />
            </Link>
          </Flex>

          {!isBilling && (
            <Flex flex="1" justifyContent={"flex-end"}>
              <Box id="top-nav-desktop" display={{ base: "none", md: "block" }}>
                <Link
                  whiteSpace="nowrap"
                  fontSize="xs"
                  fontWeight={"bold"}
                  color="brand.gray.800"
                  href="/#pricing"
                  mr="5"
                  mt="9px"
                >
                  {l["layout.nav.pricing"]}
                </Link>

                <Link
                  whiteSpace="nowrap"
                  fontSize="xs"
                  fontWeight={"bold"}
                  color="brand.gray.800"
                  href="/#faq"
                  mr="5"
                  mt="9px"
                >
                  {l["layout.nav.faq"]}
                </Link>

                {isDefaultEnglish && (
                  <>
                    <Link
                      whiteSpace="nowrap"
                      fontSize="xs"
                      fontWeight={"bold"}
                      color="brand.gray.800"
                      mr="5"
                      mt="9px"
                      href="/languages"
                    >
                      {l["layout.nav.languages"]}
                    </Link>

                    <Link
                      whiteSpace="nowrap"
                      fontSize="xs"
                      fontWeight={"bold"}
                      color="brand.gray.800"
                      mr="5"
                      mt="9px"
                      href="/blog"
                    >
                      {l["layout.nav.blog"]}
                    </Link>
                  </>
                )}
                <Link
                  whiteSpace="nowrap"
                  fontSize="xs"
                  fontWeight={"bold"}
                  color="brand.gray.800"
                  mr="5"
                  mt="9px"
                  href="/partners/schools"
                >
                  {l["layout.nav.schools"]}
                </Link>

                <Link
                  whiteSpace="nowrap"
                  id="go-to-app-header-link"
                  fontSize="xs"
                  fontWeight={"bold"}
                  color="brand.gray.500"
                  onClick={onLogin}
                  mr="5"
                  mt="9px"
                >
                  {user.sessionUser
                    ? l["layout.nav.goToApp"]
                    : l["layout.nav.signIn"]}
                </Link>
              </Box>
              <Box display={{ md: "none" }}>
                <Menu id="main-navigation">
                  <MenuButton
                    as={Button}
                    rightIcon={<RiArrowDownSLine />}
                    variant="outline"
                    size="sm"
                  >
                    {l["layout.nav.menu"]}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => router.push("/#pricing")}>
                      {l["layout.nav.pricing"]}
                    </MenuItem>

                    <MenuItem onClick={() => router.push("/#faq")}>
                      {l["layout.nav.faq"]}
                    </MenuItem>

                    <MenuItem onClick={() => router.push("/partners/schools")}>
                      {l["layout.nav.schools"]}
                    </MenuItem>

                    <MenuItem onClick={() => router.push("/partners/business")}>
                      {l["layout.nav.teams"]}
                    </MenuItem>

                    <MenuItem onClick={() => router.push("/languages")}>
                      {l["layout.nav.languages"]}
                    </MenuItem>

                    <MenuItem onClick={() => router.push("/blog")}>
                      {l["layout.nav.blog"]}
                    </MenuItem>

                    <MenuItem
                      onClick={() => router.push("/partners/affiliates")}
                    >
                      {l["layout.nav.affiliateProgram"]}
                    </MenuItem>
                    <MenuItem onClick={onLogin}>
                      {user.sessionUser
                        ? l["layout.nav.goToApp"]
                        : l["layout.nav.signIn"]}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          )}
        </Flex>
        <Flex flexDir={"column"} minHeight="calc(100vh - 342px)">
          {children}
        </Flex>
        {isDefaultEnglish &&
          !isFrontpageOrBilling &&
          !isQuiz &&
          !isPartners && (
            <Box
              id="footer-cta"
              width={"100%"}
              backgroundColor="gray.100"
              py={12}
            >
              <Section>
                <Flex flexDir={"column"} width={"100%"} alignItems={"center"}>
                  <Card
                    direction={{ base: "column", sm: "row" }}
                    overflow="hidden"
                    variant="outline"
                    maxW={"800px"}
                  >
                    <Image
                      objectFit="cover"
                      maxW={{ base: "100%", sm: "200px" }}
                      src="/landing-1-thumb.jpeg"
                      alt="Talkio AI"
                    />

                    <Stack>
                      <CardBody>
                        <Heading fontSize={"3xl"}>Talkio AI</Heading>

                        <Text py="2">{l["layout.cta.description"]}</Text>
                      </CardBody>

                      <CardFooter>
                        <Button
                          as="a"
                          href="/"
                          variant="solid"
                          colorScheme="yellow"
                          backgroundColor={"#f6d78b"}
                        >
                          {l["layout.cta.button"]}
                        </Button>
                      </CardFooter>
                    </Stack>
                  </Card>
                </Flex>
              </Section>
            </Box>
          )}

        {!isQuiz && (
          <Flex
            id="badge-bar"
            backgroundColor="gray.200"
            py={10}
            justifyContent="center"
            alignItems={{ base: "center", md: "flex-start" }}
            flexDir={{ base: "column", md: "row" }}
          >
            <ProductHuntBadge />

            <Image
              src="/stripe-badge-white.png"
              alt="Stripe Verified Partner"
              height="80px"
              mx={{ base: 0, md: 12 }}
              my={{ base: 5, md: 0 }}
            />

            <Image src="/ssl-secure.png" alt="SSL Secure" height="50px" />
          </Flex>
        )}

        {!isCampaign && <NewsletterSignup />}

        <Box
          id="footer"
          as="footer"
          backgroundColor="brand.gray.700"
          color="white"
          width="100%"
        >
          <Flex
            justifyContent={"space-evenly"}
            py={12}
            px={10}
            flexDir={{ base: "column", md: "row" }}
            lineHeight={2}
            flex="1"
          >
            <Flex
              width="100%"
              textAlign={{ base: "center", md: "left" }}
              mb={10}
              justifyContent={{ base: "center", md: "flex-start" }}
            >
              <Flex flexDirection={"column"}>
                <Heading fontSize="md" width="100%">
                  {l["layout.footer.pages"]}
                </Heading>
                <Link flex="1" href="/hub">
                  {l["layout.footer.learningHub"]}
                </Link>
                <Link flex="1" href="/blog">
                  {l["layout.footer.blog"]}
                </Link>
                <Link flex="1" href="/about">
                  {l["layout.footer.about"]}
                </Link>
                <Link flex="1" href="/partners/affiliates">
                  {l["layout.footer.affiliateProgram"]}
                </Link>
                <Link flex="1" href="/partners/schools">
                  {l["layout.footer.schools"]}
                </Link>
                <Link flex="1" href="/partners/business">
                  {l["layout.footer.teams"]}
                </Link>
                <Link flex="1" href="/guide/beginner-intro">
                  {l["layout.footer.languageGuide"]}
                </Link>
                <Link flex="1" href="/legal/terms">
                  {l["layout.footer.termsConditions"]}
                </Link>
                <Link flex="1" href="/legal/privacy">
                  {l["layout.footer.privacyPolicy"]}
                </Link>

                {user.sessionUser ? (
                  <Link flex="1" onClick={() => signOut({ callbackUrl: "/" })}>
                    {l["layout.footer.logout"]}
                  </Link>
                ) : (
                  <Link
                    flex="1"
                    onClick={() => router.push("/authentication/signin")}
                  >
                    {l["layout.footer.login"]}
                  </Link>
                )}
              </Flex>
            </Flex>
            <Flex textAlign={"center"} width="100%" flexDir={"column"} mb={10}>
              <Heading textAlign={"center"} fontSize="md" width="100%" mb="5">
                {l["layout.footer.blogPosts"]}
              </Heading>

              <Link
                mb={3}
                fontSize="sm"
                href="/blog/posts/interactive-guide"
                lineHeight={1.2}
              >
                {l["layout.footer.blogPost1"]}
              </Link>

              <Link
                mb={3}
                fontSize="sm"
                href="/blog/posts/talkio-gpts"
                lineHeight={1.2}
              >
                {l["layout.footer.blogPost2"]}
              </Link>

              <Link
                mb={3}
                fontSize="sm"
                href="/blog/posts/prepare-for-ielts-with-talkio"
                lineHeight={1.2}
              >
                {l["layout.footer.blogPost3"]}
              </Link>
              <Link
                mb={3}
                fontSize="sm"
                href="/blog/posts/three-effective-ways-of-practice"
                lineHeight={1.2}
              >
                {l["layout.footer.blogPost4"]}
              </Link>
              <Link
                mb={3}
                fontSize="sm"
                href="/blog/posts/acing-your-english-test"
                lineHeight={1.2}
              >
                {l["layout.footer.blogPost5"]}
              </Link>

              <Link
                mb={3}
                fontSize="sm"
                href="/blog/posts/prepare-for-toefl-with-talkio"
                lineHeight={1.2}
              >
                {l["layout.footer.blogPost6"]}
              </Link>
            </Flex>

            <Flex
              width="100%"
              flexDirection={"column"}
              textAlign={{ base: "center", md: "right" }}
            >
              <Heading fontSize="md" width="100%" mb="5">
                {l["layout.footer.contact"]}
              </Heading>
              <Link
                fontSize="xs"
                lineHeight={1.4}
                mb={2}
                href="mailto:hello@talkio.ai"
              >
                hello@talkio.ai
              </Link>

              <Text mb={2} fontSize="xs" lineHeight={1.4}>
                Aidia ApS <br />
                Nøddehaven 4 <br />
                3500 Værløse <br />
                Denmark
              </Text>

              <Text lineHeight={1.4} fontSize="xs">
                {process.env.NEXT_PUBLIC_URL}
              </Text>

              {baseLangCode && (
                <Box color="black">
                  <LanguageSelectorLandingDynamic
                    defaultBaseLangCode={baseLangCode}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
          <Box width="100%" textAlign="center" pb={5}>
            <Text fontSize={"sm"}>
              ©Aidia ApS {new Date().getFullYear()}. {l["layout.footer.rights"]}
            </Text>
            <Link
              mt={2}
              fontSize={"xs"}
              rel="noopener noreferrer nofollow"
              href="https://www.freepik.com/free-vector/chatbot-voice-controlled-virtual-assistant-abstract-concept-illustration_12290856.htm#query=robot%20voice%20chat&position=2&from_view=search&track=ais"
            >
              {l["layout.footer.illustrationCredits"]}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
