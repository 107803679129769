const l = {
  "settings.title": "Settings",
  "settings.subtitle": "Customize your experience with the tutor.",
  "settings.language": "Language",
  "settings.yourLanguage": "Your Language",
  "settings.uiLanguage": "UI Language",
  "settings.practiceLanguage": "Practice Language",
  "settings.proficiency": "proficiency",
  "settings.tutor": "Tutor",
  "settings.name": "Name",
  "settings.talkingSpeed": "Talking speed",
  "settings.speechInput": "Speech Input",
  "settings.autoPunctuation": "Automatic Punctuation",
  "settings.autoPunctuationDescription":
    "Automatically add punctuation when you pause",
  "settings.multiLingualSpeechRecognition": "Multilingual Speech Recognition",
  "settings.multiLingualSpeechRecognitionDescription":
    "The tutor will recognize speech in both the practice language and your native language",
  "settings.handsFree": "Hands-free chat mode",
  "settings.handsFreeDescription":
    "Talk without needing to click the microphone button. Your message will be sent automatically when silence is detected or, if enabled, a trigger word is spoken",
  "settings.submitWithTriggerWord": "Submit using trigger word",
  "settings.submitWithTriggerWordDescription":
    "Use a trigger word to submit your message instead of silence detection",
  "settings.triggerWord": "Trigger Word",
  "settings.triggerWordDescription":
    "Pick a word that is simple to pronounce in your practice language",
  "settings.translations": "Translations",
  "settings.autoTranslate": "Auto Translate",
  "settings.autoTranslateDescription":
    "Automatically translate messages from the tutor to your native language",
  "settings.showRomanization": "Show Romanization",
  "settings.showRomanizationDescription":
    "Show romanization of the latest chat message for script languages",
  "settings.audio": "Audio",
  "settings.autoPlayTutorResponse": "Auto-play tutor responses",
  "settings.autoPlayTutorResponseDescription":
    "Automatically play tutor responses aloud",
  "settings.playbackUiAudio": "Enable UI audio",
  "settings.playbackUiAudioDescription":
    "Enable audio feedback for UI interactions",

  "wordbook.title": "Wordbook",
  "wordbook.subtitle":
    "Track the words you learn; your tutor will help you use them to boost your memory",
  "wordbook.noWord": " No words saved for the chosen language",
  "wordbook.yourPracticeLanguage": "Your practice language",
  "wordbook.word": "Word",
  "wordbook.addToWordbook": "Add to Wordbook",
  "wordbook.pleaseEnterIn": "Please enter a word in",
  "wordbook.selectWord": "Select a word",

  "pronunciation.title": "Pronunciation",
  "pronunciation.subtitle": "Practice pronunciation and fluency",
  "pronunciation.intro":
    "The tutor will generate suitable sentences, tailored to your proficiency level, which is set to",
  "pronunciation.startPracticing": "Start practicing",
  "pronunciation.done": "Done",
  "pronunciation.cancel": "Cancel",
  "pronunciation.tryAgain": "Please try again",
  "pronunciation.wordByWord.loading": "Loading...",
  "pronunciation.wordByWord.stopReading": "Stop reading",
  "pronunciation.wordByWord.readSentence": "Read sentence",
  "pronunciation.wordByWord.newSentence": "New sentence",
  "pronunciation.wordByWord.retrySentence": "Retry sentence",
  "pronunciation.saveProgress.progressSaved":
    "Your pronunciation progress has been saved",
  "pronunciation.saveProgress.progressNotSaved":
    "Unable to save your pronunciation progress. Please check your connection and try again.",
  "pronunciation.saveProgress.saveProgress":
    "Save sentence and score to track progress and retry later.",
  "pronunciation.saveProgress.saved": "Saved",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Save sentence & assessment",
  "pronunciation.assessmentStats.noResults": "No result to show",
  "pronunciation.assessmentStats.pronunciation": "Pronunciation",
  "pronunciation.assessmentStats.pronunciationScore": "Pronunciation score",
  "pronunciation.assessmentStats.pronunciationDescription":
    "The Pronunciation Score represents the quality of pronunciation in the given speech. It is calculated by combining Accuracy Score, Fluency Score, and Completeness Score, each assigned with a specific weight.",
  "pronunciation.assessmentStats.accuracy": "Accuracy",
  "pronunciation.assessmentStats.accuracyScore": "Pronunciation accuracy",
  "pronunciation.assessmentStats.accuracyDescription":
    "Pronunciation accuracy measures how well spoken words match those of a native speaker. Accuracy scores for syllables, words, and full text are based on phoneme-level accuracy and adjusted according to assessment goals.",
  "pronunciation.assessmentStats.completeness": "Completeness",
  "pronunciation.assessmentStats.completenessScore": "Completeness",
  "pronunciation.assessmentStats.completenessDescription":
    "Completeness of speech is determined by comparing the number of words pronounced to the total number of words in the reference text.",
  "pronunciation.assessmentStats.fluency": "Fluency",
  "pronunciation.assessmentStats.fluencyScore": "Fluency",
  "pronunciation.assessmentStats.fluencyDescription":
    "Fluency of the given speech. Fluency indicates how closely the speech matches a native speaker's use of silent breaks between words.",
  "pronunciation.assessmentStats.keepPracticing": "Keep practicing!",
  "pronunciation.assessmentStats.makingProgress": "Making progress!",
  "pronunciation.assessmentStats.wellDone": "Well done!",
  "pronunciation.assessmentStats.almostThere": "Almost there!",
  "pronunciation.assessmentStats.excellentJob": "Excellent job!",
  "pronunciation.history.saveAssessments": "Saved assessments",
  "pronunciation.history.noAssessments": "No assessments yet",
  "pronunciation.history.score": "Score",
  "pronunciation.history.tryAgain": "Try again",

  "account.unableToLoad": "Unable to load user details",
  "account.title": "Account",
  "account.renewFailed": "Subscription failed to renew",
  "account.reviewPaymentDetails": "Please review your payment details",
  "account.subscriptionInactive": "Subscription inactive",
  "account.creditCardDeclined": "Your credit card transaction was declined.",
  "account.updateCreditCard":
    "To continue using Talkio AI, please provide another payment method or update your payment details on the",
  "account.subscriptionManagementPage": "subscription management page",
  "account.questionContactUs":
    "If you have any questions or comments do not hesitate to contact us at",
  "account.billing": "Billing",
  "account.plan": "Plan",
  "account.amount": "Amount",
  "account.usage": "Usage",
  "account.usageDescription":
    "The usage limit is determined by the total number of characters involved in conversations, which includes both the text input sent to the AI and the text generated by the AI in response. This limit is reset on a monthly basis. {limit} characters are roughly equivalent to {estHours} hours of conversation.",
  "account.requestRefund": "Request refund",
  "account.manageSubscription": "Manage subscription",
  "account.subscriptionManagedBy": "Your subscription is managed by",
  "account.contactAdmin":
    "Please reach out to your organization administrator to change or cancel your subscription.",
  "account.subscriptionManagedByPartner": "Your subscription is managed by",
  "account.contactPartner":
    "Please reach out to {userPlanId} to change or cancel your subscription.",
  "account.accessSubscriptionManagement":
    "On the subscription management page, you can update your billing information, change your subscription plan, or cancel your subscription.",
  "account.goToSubscriptionManagement": "Go to subscription management",
  "account.feedback": "Feedback",
  "account.feedbackDescription":
    "Your feedback is invaluable to us! If you have any suggestions or areas you think we could enhance, please do not hesitate to",
  "account.feedbackCallToAction": "share your thoughts",
  "account.reviewDescription":
    "If you're enjoying the app, we'd be grateful for",
  "account.reviewCallToAction": "your review!",
  "account.signOutDescription":
    "Sign out of your account or change your password using the buttons below.",
  "account.changePassword": "Change Password",
  "account.userEmail": "User",
  "account.connection": "Connection",
  "intro.title": "Welcome to {serviceName}",
  "intro.subtitle": "Let's get you started with a few questions",
  "intro.userLanguageTitle": "Language",
  "intro.userLanguageSubtitle": "What is your native language?",
  "intro.practiceLanguageTitle": "Language",
  "intro.practiceLanguageSubtitle": "What language do you want to practice?",
  "intro.proficiencyTitle": "Proficiency",
  "intro.proficiencyDescription":
    "What's your proficiency in {practiceLanguage}",
  "intro.completionModal.title": "You are all set!",
  "intro.completionModal.buttonText": "Time to start talking",

  "hooks.useConversation.noSpeech": "Speech not detected or volume too low",
  "hooks.useAssessment.unableToFetchPinyin": "Unable to fetch pinyin",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Unable to connect to the pronunciation assessment server",
  "hooks.useAssessment.noSpeech": "No speech detected",
  "hooks.useAssessment.retryError": "Error",
  "hooks.useAssessment.retryErrorDescription":
    "Unable to load the saved assessment",

  "hooks.useWordbook.deleteConfirm":
    "Are you sure you want to delete this word?",
  "hooks.useWordbook.unableToDeleteWord":
    "Unable to delete word. Please try again in a few seconds.",

  "components.miniTranslator.translate": "Translate",
  "components.miniTranslator.to": "to",

  "components.conversationDownloadModal.user": "User",
  "components.conversationDownloadModal.tutor": "Tutor",
  "components.conversationDownloadButton.export": "Export Chat",

  "components.micPermissionButton.notAllowed": "Microphone access not allowed",
  "components.micPermissionButton.turnOnMicAndSound":
    "Turn on microphone & sound",
  "components.micPermissionButton.pleaseAllow":
    "Please allow microphone access",

  "components.languageSelector.managedByOrganization":
    "Managed by organization",

  "components.languageSelector.selectLanguage": "Select Language",
  "components.languageSelector.fullList": "Show full list",
  "components.languageSelector.simpleList": "Show simplified list",

  "components.proficiencySelector.helpMeChoose": "Help me choose",
  "components.proficiencySelector.descriptionsTitle":
    "Proficiency descriptions",
  "components.proficiencySelector.descriptionsSubtitle":
    "Here are descriptions of the different language proficiency levels.",
  "components.proficiencySelector.beginnerLabel": "Beginner",
  "components.proficiencySelector.beginnerDescription":
    "The individual can use basic expressions to meet practical needs with clear communication and willingness from others.",
  "components.proficiencySelector.elementaryLabel": "Elementary",
  "components.proficiencySelector.elementaryDescription":
    "The individual understands important topics, communicates effectively in routine matters, and can explain their background and necessities.",
  "components.proficiencySelector.intermediateLabel": "Intermediate",
  "components.proficiencySelector.intermediateDescription":
    "The individual understands and communicates basic information on common subjects, manages travel situations, and expresses personal experiences, opinions, and plans.",
  "components.proficiencySelector.upperIntermediateLabel": "Upper-Intermediate",
  "components.proficiencySelector.upperIntermediateDescription":
    "The individual understands complex practical and theoretical text, communicates fluently with native speakers, generates comprehensive text, and expresses their perspective on current issues.",
  "components.proficiencySelector.advancedLabel": "Advanced",
  "components.proficiencySelector.advancedDescription":
    "The individual comprehends complex texts with implicit meaning, communicates fluently and proficiently, and generates well-constructed and coherent text on complicated topics.",
  "components.proficiencySelector.close": "Close",

  "components.readSpeedSlider.speed": "Speed",
  "components.readSpeedSlider.verySlow": "Very slow",
  "components.readSpeedSlider.slow": "Slow",
  "components.readSpeedSlider.normal": "Normal",
  "components.readSpeedSlider.fast": "Fast",
  "components.readSpeedSlider.veryFast": "Very fast",

  "components.speechRecognitionSelector.voiceInputEngine": "Voice Input Engine",
  "components.speechRecognitionSelector.default": "Standard",
  "components.speechRecognitionSelector.alternative": "Precision",

  "components.saveWord.save": "Save",
  "components.saveWord.notUnderstood":
    "The AI did not understand this word. Please try again.",
  "components.saveWord.failed": "Failed to save. Please try again.",
  "components.saveWord.savedToWordbook": "saved to your wordbook ",

  "components.wordInformation.content.description": "Description",
  "components.wordInformation.content.sentences": "Sentences",

  "components.textWithActions.readAloud": "Read aloud",
  "components.textWithActions.saveWord": "Save word",
  "components.textWithActions.learnMore": "Learn more",

  "components.pwaPromptIos.title": "Install {app} on your device",
  "components.pwaPromptIos.step1": " Tap the Share icon",
  "components.pwaPromptIos.step2": "Select 'Add to Home Screen'",
  "components.pwaPromptIos.step3": "No App Store needed!",
  "components.pwaPromptAndroid.title": "Install App",
};

export default l;
