import { useEffect, useState } from "react";

export function useIsInIframe(): boolean {
  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
    const checkIframe = () => {
      try {
        setIsInIframe(window.self !== window.top);
      } catch (e) {
        setIsInIframe(false);
      }
    };
    checkIframe();
  }, []);

  return isInIframe;
}
